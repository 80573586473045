import { NgModule, Component, Pipe, OnInit, OnDestroy, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
	ReactiveFormsModule,
	FormsModule,
	FormGroup,
	FormControl,
	Validators,
	FormBuilder
} from '@angular/forms';

import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { finalize } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';

import { IMyDpOptions, IMyDefaultMonth } from 'mydatepicker';

import { GenderApi, CivilStatusApi, ProvinceApi, MunicipalityApi } from '../../../../../assets/js/lb-sdk';
import { UserDataApi, RoleDataApi, CustomerInfoApi, CustomerCardApi, ProductApi } from '../../../../../assets/js/lb-sdk/services';


import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { RegisterModalComponent } from './register-modal.component';

import { ModalRegistrationContentComponent } from './modal-registration-summary.component';

import { Subscription } from 'rxjs/Subscription';

const datenow = new Date();

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
	bsModalRef: BsModalRef;
	
	isLoading: boolean = false;
	tab = 0;
	form: FormGroup;
	registerForm: FormGroup;

	options = {
		noMiddle: false,
		onlyOneName: false
	}

	panel: any = {
		policy: true,
		reference: false
	}

	cardNumber: FormControl;
	policyNumber: FormControl;
	referenceNumber: FormControl;
	firstName: FormControl;
	middleName: FormControl;
	lastName: FormControl;
	nameExtension: FormControl;
	birthDate: FormControl;
	gender: FormControl;
	civilStatus: FormControl;
	street: FormControl;
	province: FormControl;
	municipality: FormControl;
	zipCode: FormControl;
	mobileNumber: FormControl;
	emailAddress: FormControl;

	username: FormControl;
	password: FormControl;
	confirmPassword: FormControl;
	unmatched: boolean = false;
	captcha: string = null;
	site_key = '6LdDiIMUAAAAAPOiL5iFgKBrFK3Mv6ObmKZnuXzF';

	emailValid: boolean = true;
	mobileNumberValid: boolean = true;
	referenceNumberData: any = {};
	productData: any;
	validatingReferenceNumber: boolean = false;

	list: any = [
		true, // policy
		false, // member
		false, // additional
		false // register
	];

	genders = [];
	civilStatuses = [];
	provinces = [];
	municipalities = [];

	public myDatePickerOptions: IMyDpOptions = {
		// other options...
		dateFormat: 'mm/dd/yyyy',
		disableSince: { year: datenow.getFullYear(), month: datenow.getMonth() + 1, day: datenow.getDate() },
		openSelectorOnInputClick: true,
		editableDateField: false
	};

	public defaultMonth: IMyDefaultMonth = { defMonth:  datenow.getMonth() + 1+"/"+datenow.getFullYear() };



	verification: any = {
		name: null,
		emailAddress: null
	}

	// card_link: string = ''; // = 'https://shop.maxicare.com.ph/main/img/primagold.png';

	gender_reloading: boolean = false;
	civilStatus_reloading: boolean = false;
	province_reloading: boolean = false;

	server: any = window.location.origin;


	ProductType: any = { PRIMA: '42', LITE: '41', EReady: '40', EReadyAdvance: '43' }; // techd-02 2019 11 12
	sample = { show: false, PlanType: 'D', ProductType: '42' } 
	welcome: any = { show: false, link: false, message: '', activateMessage: ''}

	agreementOptions: any = { privacy: false, updates: false, member: false, kyc: false }; // techd-02 2019 11 12

	registrationModalConfirmSubscription: Subscription;
	registrationModalEditSubscription: Subscription;

	constructor(
		private route: ActivatedRoute,
		private productApi: ProductApi,
		private genderApi: GenderApi,
		private civilStatusApi: CivilStatusApi,
		private provinceApi: ProvinceApi,
		private municipalityApi: MunicipalityApi,
		private userDataApi: UserDataApi,
		private roleDataApi: RoleDataApi,
		private customerInfoApi: CustomerInfoApi,
		private customerCardApi: CustomerCardApi,
		private toastr: ToastrService,
		private modalService: BsModalService
	) {}

	ngOnInit() {
		if (this.sample.show) {
			const ref = this.sample // this.fetchReferenceNumber;
			this.productApi.findOne({ where : { and: [
				{ planType: ref.PlanType },
				{ productType: ref.ProductType }
				]
			}}).subscribe(
				response => {
					if(response) this.formatWelcome(response, true)
				},
				error => {
					console.log('ERROR: ', error.code);
				}
			);
		}

		this.createFormControls();
		this.createForm();

		this.checkCard();

		this.getGenders();
		this.getCivilStatuses();
		this.getProvinces();

		// if(window.location.origin == window.location.origin){
		// 	this.site_key = '6Ld8_HYUAAAAABvNk4HZ0hmYk4QQT1gaKZnKomfO';
		// }

		// this.formatBirthdate();
	}

	resetForm() {
		this.tab = 0;

		this.cardNumber.reset();
		this.policyNumber.reset();
		this.referenceNumber.reset();
		this.firstName.reset();
		this.middleName.reset();
		this.lastName.reset();
		this.nameExtension.reset();
		this.birthDate.reset();
		this.gender.reset();
		this.civilStatus.reset();
		this.street.reset();
		this.province.reset();
		this.municipality.reset();
		this.zipCode.reset();
		this.mobileNumber.reset();
		this.emailAddress.reset();
		this.username.reset();
		this.password.reset();
		this.confirmPassword.reset();
	}

	referenceNumberOnChange() {
		this.referenceNumberData = {};

		this.registerForm.patchValue({
			policy: {
				referenceNumber: this.referenceNumber.value
			}
		});

		if(this.referenceNumber.value && this.referenceNumber.value.length < 13) {
			return false;
		}

		this.fetchReferenceNumberDetails(this.referenceNumber.value);
	}

	checkCard() {
		const referenceCode = this.route.snapshot.queryParams['card'];
		if (referenceCode) {
			this.fetchReferenceNumberDetails(referenceCode,true)
		}
	}

	fetchReferenceNumberDetails(referenceNumber: string,switchTab: boolean=false) {
		if(referenceNumber){
			this.validatingReferenceNumber = true;
			this.referenceNumberData = {};
 		   	this.customerCardApi.fetchReferenceNumber(referenceNumber)
 		   	.subscribe(
 			   	response => {
 			   		console.log('referenceNumberData', response.response.d);
 				   	this.validatingReferenceNumber = false;
					this.referenceNumberData = response.response.d;
					if (this.referenceNumberData.ReferenceNumber !== '') {
						// this.getProducts(this.referenceNumberData.PlanType,this.referenceNumberData.ProductType);
						// console.log(this.referenceNumberData);
						this.referenceNumber.setValue(referenceNumber, { onlySelf: true });

						this.formatBirthdate();
						if (switchTab) this.tab = 1
					}
 			   	},
 			   	error => {
 				   	this.validatingReferenceNumber = false;
					this.referenceNumberData = { hasMessage: true, message: error.message };
					console.log('ERROR: ', error);
					console.log('ERROR: ', error.code);
					console.log(this.referenceNumberData)
					this.referenceNumber.setValue(referenceNumber, { onlySelf: true });
 			   	}
 		   	);
		}
	}

	createFormControls() {
		const policy = JSON.parse(localStorage.getItem('policy'));
		const member = JSON.parse(localStorage.getItem('member'));
		const additional = JSON.parse(localStorage.getItem('additional'));

		this.cardNumber = new FormControl((policy) ? policy.cardNumber : '', [Validators.required, Validators.minLength(16)]);
		this.policyNumber = new FormControl((policy) ? policy.policyNumber : '', [Validators.required, Validators.minLength(12)]);
		this.referenceNumber = new FormControl((policy) ? policy.referenceNumber : '', [
			Validators.required, Validators.minLength(13),
			(control: FormControl) => {
				console.log('this.referenceNumberData', this.referenceNumberData);
				if(this.referenceNumberData['MemberID'] === undefined ||
					(this.referenceNumberData['ReferenceNumber'] == '' &&  
					this.referenceNumberData['CardNumber'] == '' &&
					this.referenceNumberData['PolicyNumber'] == '' ) 
					){
					return {
						notvalidated: true
					}
				}

				if(this.referenceNumberData.CardNumber) {
					return {
						used: true
					}
				}

				if(this.referenceNumberData.message) {
					return {
						hasMessage: true,
						message: this.referenceNumberData.message
					}
				}

				return null
			}
		]);

		this.firstName = new FormControl((member) ? member.firstName : '', Validators.required);
		this.middleName = new FormControl((member) ? member.middleName : '', Validators.required);
		// this.lastName = new FormControl((member) ? member.lastName : '', Validators.required);
		this.lastName = new FormControl((member) ? member.lastName : '', Validators.required);
		this.nameExtension = new FormControl('');
		this.birthDate = new FormControl((member) ? member.birthDate : '', Validators.required);
		this.gender = new FormControl((member) ? member.gender : '', Validators.required);
		this.civilStatus = new FormControl((member) ? member.civilStatus : '', Validators.required);
		this.street = new FormControl((additional) ? additional.street : '', [Validators.required, Validators.maxLength(168)]);
		this.province = new FormControl((additional) ? additional.province : '', Validators.required);
		this.municipality = new FormControl((additional) ? additional.municipality : '', Validators.required);
		this.zipCode = new FormControl((additional) ? additional.zipCode : '', Validators.required);
		this.zipCode.disable();
		this.mobileNumber = new FormControl((additional) ? additional.mobileNumber : '', [
			Validators.required,
			(control: FormControl) => {
				if(!this.mobileNumberValid){
					return {
						mismatch: true
					}
				}

				return null
			}
		]);
		this.emailAddress = new FormControl((additional) ? additional.emailAddress : '', [
			Validators.required,
			// Validators.pattern("[^ @]*@[^ @]*")
			Validators.pattern('[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}'),
			(control: FormControl) => {
				if(!this.emailValid){
					return {
						mismatch: true
					}
				}

				return null
			}
		]);

		this.username = new FormControl((this.emailAddress.value) ? this.emailAddress.value : '', Validators.required);
		this.password = new FormControl('', Validators.required);
		this.confirmPassword = new FormControl('', [
			Validators.required,
			(control: FormControl) => {
				// console.log(control);
				if(this.password.value !== control.value){
					return {
						mismatch: true
					}
				}

				return null
			}
		]);
	}

	createForm() {

		this.registerForm = new FormGroup({
			policy: new FormGroup({
				cardNumber: this.cardNumber,
				policyNumber: this.policyNumber,
				referenceNumber: this.referenceNumber,
			}),
			member: new FormGroup({
				firstName: this.firstName,
				middleName: this.middleName,
				lastName: this.lastName,
				nameExtension: this.nameExtension,
				birthDate: this.birthDate,
				gender: this.gender,
				civilStatus: this.civilStatus
			}),
			additional: new FormGroup({
				street: this.street,
				province: this.province,
				municipality: this.municipality,
				zipCode: this.zipCode,
				mobileNumber: this.mobileNumber,
				emailAddress: this.emailAddress
			}),
			account: new FormGroup({
				username: this.username,
				password: this.password,
				confirmPassword: this.confirmPassword
			})
		});
	}

	formatBirthdate() {
		const datenow = new Date();
		// console.log('formatBirthdate', this.referenceNumberData);
		let productType=this.referenceNumberData.ProductType;
		let planType=this.referenceNumberData.PlanType;
		// console.log(productType, planType);
		// console.log('formatBirthdate', this.referenceNumberData);
		switch(productType){
			case "42":
				if(planType == "S") {
					// this.birthDate.setValue( { date: { year: datenow.getFullYear(), month: datenow.getMonth() + 1, day: datenow.getDate() } }, { onlySelf: true });
					//from datenow -
					this.defaultMonth = { defMonth: ('00' + (datenow.getMonth() + 1)).slice(-2) + "/" + (datenow.getFullYear()) };
					console.log('default month', this.defaultMonth);
					this.myDatePickerOptions = {
						dateFormat: 'mm/dd/yyyy',
						disableUntil: { year: datenow.getFullYear() - 60, month: datenow.getMonth() + 1, day: datenow.getDate() },
						disableSince: { year: datenow.getFullYear(), month: datenow.getMonth() + 1, day: datenow.getDate() - 7 },
						openSelectorOnInputClick: true,
						editableDateField: false
					};
				}
				else if(planType == "S") {
				
					// this.birthDate.setValue( { date: { year: datenow.getFullYear() - 60, month: datenow.getMonth() + 1, day: datenow.getDate() - 1 } }, { onlySelf: true });
					//from datenow -
					this.defaultMonth = { defMonth: ('00' + (datenow.getMonth() + 1)).slice(-2) + "/" + (datenow.getFullYear() - 60) };
					console.log('default month', this.defaultMonth);
					this.myDatePickerOptions = {
						dateFormat: 'mm/dd/yyyy',
						// disableUntil: { year: datenow.getFullYear() - 59, month: datenow.getMonth() + 1, day: datenow.getDate() - 1 },
						disableSince: { year: datenow.getFullYear() - 60, month: datenow.getMonth() + 1, day: datenow.getDate() + 1 },
						openSelectorOnInputClick: true,
						editableDateField: false
					};
				}
				else {
					this.defaultMonth = { defMonth: ('00' + (datenow.getMonth() + 1)).slice(-2) + "/" + (datenow.getFullYear() - 60) };
					console.log('default month', this.defaultMonth);
					this.myDatePickerOptions = {
						dateFormat: 'mm/dd/yyyy',
						openSelectorOnInputClick: true,
						editableDateField: false
					};
				}
				break;
			case "41":
				// this.birthDate.setValue( { date: { year: datenow.getFullYear() - 2, month: datenow.getMonth() + 1, day: datenow.getDate() } }, { onlySelf: true });
				//from datenow -
				this.defaultMonth = { defMonth: ('00' + (datenow.getMonth() + 1)).slice(-2) + "/" + (datenow.getFullYear() - 2) };
				console.log('default month', this.defaultMonth);
				this.myDatePickerOptions = {
					dateFormat: 'mm/dd/yyyy',
					disableUntil: { year: datenow.getFullYear() - 61, month: datenow.getMonth() + 1, day: datenow.getDate() },
					disableSince: { year: datenow.getFullYear() - 2, month: datenow.getMonth() + 1, day: datenow.getDate() + 1 },
					openSelectorOnInputClick: true,
					editableDateField: false
				};
				break;
			case "40":
			case "43":
				// this.birthDate.setValue( { date: { year: datenow.getFullYear(), month: datenow.getMonth() + 1, day: datenow.getDate() - 15 } }, { onlySelf: true });
				//from datenow -
				this.defaultMonth = { defMonth: ('00' + (datenow.getMonth() + 1)).slice(-2) + "/" + (datenow.getFullYear()) };
				console.log('default month', this.defaultMonth);
				this.myDatePickerOptions = {
					dateFormat: 'mm/dd/yyyy',
					disableUntil: { year: datenow.getFullYear() - 66, month: datenow.getMonth() + 1, day: datenow.getDate() },
					disableSince: { year: datenow.getFullYear(), month: datenow.getMonth() + 1, day: datenow.getDate() - 7 },
					openSelectorOnInputClick: true,
					editableDateField: false
				};
				break;
		}

		// this.defaultMonth = { defMonth: "02/2019" };

	}

	nameOptionsChanged(opt) {
		this.options[opt] = !this.options[opt];
		console.log(this.options);
		for(const i in this.options){
			if(i != opt){
				this.options[i] = false;
			}
		}

		this.middleName.enable();
		this.lastName.enable();
		this.nameExtension.enable();

		this.middleName.setValidators(Validators.required);
		this.middleName.setValidators(Validators.required);

		if(this.options.noMiddle){
			this.middleName.reset();

			this.middleName.disable();

			this.registerForm.patchValue({
				member: {
					middleName: ''
				}
			});

			this.middleName.setValidators(null);
		}

		if(this.options.onlyOneName){
			this.middleName.reset();
			this.lastName.reset();
			this.nameExtension.reset();

			this.middleName.disable();
			this.lastName.disable();
			this.nameExtension.disable();

			this.registerForm.patchValue({
				member: {
					middleName: '',
					lastName: ''
				}
			});

			this.middleName.setValidators(null);
			this.middleName.setValidators(null);
		}
		// console.log(this.options);
	}

	resolved(captchaResponse: string) {
		this.captcha = captchaResponse;
	}

	nextButtonStatus() {
		const policy = this.checkPolicy();
		const member = this.checkMember();
		const additional = this.checkAdditional();

		let ret = null;
		if(this.tab == 0 && !policy){
			ret = 'disabled';
		} else if(this.tab == 1 && !member){
			ret = 'disabled';
		} else if(this.tab == 2 && !additional){
			ret = 'disabled';
		}

		return ret;
	}

	nextPrev(n) {
		const policy = this.checkPolicy();
		const member = this.checkMember();
		const additional = this.checkAdditional();

		if(this.tab == 0) {
			this.bsModalRef = this.modalService.show(RegisterModalComponent,{});
		}

		// n > 0 means next, < 0 means prev
		if(this.tab == 0 && n > 0 && !policy){ //policy form
			return false;
		} else if(this.tab == 1 && n > 0  && !member){
			return false;
		} else if(this.tab == 2 && n > 0  && !additional) {
			return false;
		}

		if (this.tab == 2 && n > 0) {		
			const selectedCivilStatusId = this.civilStatus.value;
			const civilStatusData = this.civilStatuses.find((c) => c.id == selectedCivilStatusId);
			const civilStat = this.civilStatuses.filter(cs => cs.id == this.civilStatus.value);

			const initialState = {
				firstName: this.firstName.value,
				birthDate: this.birthDate.value.formatted,
				middleName: this.middleName.value ? this.middleName.value : 'n/a',
				lastName: this.lastName.value ? this.lastName.value : 'n/a',
				cardNumber: this.cardNumber.value ? this.cardNumber.value : 'n/a',
				policyNumber: this.policyNumber.value ? this.policyNumber.value : 'n/a',
				referenceNumber: this.referenceNumber.value ? this.referenceNumber.value : 'n/a',
				nameExtension: this.nameExtension.value ? this.nameExtension.value : 'n/a',
				gender: this.gender.value,
				street: this.street.value,
				municipality: this.municipality.value.name,
				province: this.province.value.name,
				zipCode: this.zipCode.value,
				mobileNumber: this.mobileNumber.value,
                        	emailAddress: this.emailAddress.value,
				civilStatus: civilStatusData ? civilStatusData.name : civilStat[0].abbr,
			};

			this.bsModalRef = this.modalService.show(ModalRegistrationContentComponent, { initialState, ignoreBackdropClick: true, keyboard: false });

			if (this.registrationModalConfirmSubscription) {
				this.registrationModalConfirmSubscription.unsubscribe();
			}

			if (this.registrationModalEditSubscription) {
                                this.registrationModalEditSubscription.unsubscribe();
                        }

			this.registrationModalConfirmSubscription = this.bsModalRef.content.onButtonClick.subscribe((data: string) => {
				if (data === 'proceed') {
					this.tab += n;
				}
			});

			this.registrationModalEditSubscription = this.bsModalRef.content.onEditClick.subscribe((data: number) => {
                                this.tab = data;
                        });
			return;
		}

		this.tab += n;
		if(this.tab < 0){
			this.tab = 0;
		}
		else if(this.tab > 3) {
			this.tab = 3;
		}

	}

	// next(to) {
	// 	for (const i of Object.keys(this.list)) {
	// 		this.list[i] = false;
	// 	}
	// 	this.list[to] = true;
	// }

	save(from) {
		// this.next(from + 1);

		if (from === 0) {

			const policy = {
				cardNumber : this.cardNumber.value,
				policyNumber : this.policyNumber.value,
				referenceNumber : this.referenceNumber.value,
			};

			localStorage.setItem('policy', JSON.stringify(policy));
		} else if (from === 1) {
			const member = {
				firstName : this.firstName.value,
				middleName : this.middleName.value,
				lastName : this.lastName.value,
				birthDate : this.birthDate.value,
				gender : this.gender.value,
				civilStatus : this.civilStatus.value
			};

			localStorage.setItem('member', JSON.stringify(member));
		} else if (from === 2) {
			const additional = {
				street : this.street.value,
				province : this.province.value,
				municipality : this.municipality.value,
				zipCode : this.zipCode.value,
				mobileNumber : this.mobileNumber.value,
				emailAddress : this.emailAddress.value
			};

			localStorage.setItem('additional', JSON.stringify(additional));
		}
	}

	checkForm() {
		let ret = true;

		// BEGIN techd-02 2019 11 12
		// Check that all agreements are true
		var validOptions: any = { privacy: false, updates: false };
		if (this.isReferenceNumber()) {
			validOptions = this.agreementOptions;
		}
		for ( const name in validOptions) {
			if (this.agreementOptions[name] === false) {
				ret = false;
			}
		}
		// END techd-02 2019 11 12
		const policy = this.checkPolicy();
		const member = this.checkMember();
		const additional = this.checkAdditional();

		if(!policy && !member && !additional){
			ret = false;
		}

		return ret;
	}

	// BEGIN techd-02 2019 11 12
	/**
	 * 	Toggle agreement options
	 *  name: option name
	 */
	agreementOptionsChanged(name: string) {
		this.agreementOptions[name] = !this.agreementOptions[name];
	}
	// END techd-02 2019 11 12

	checkPolicy() {
		let ret = true;
		if (
			!this.cardNumber.value || !this.policyNumber.value ||
			(this.cardNumber.errors && (this.cardNumber.dirty || this.cardNumber.touched)) ||
			(this.policyNumber.errors && (this.policyNumber.dirty || this.policyNumber.touched))
		) {
			if(
				!this.referenceNumber.value ||
				(this.referenceNumber.errors && (this.referenceNumber.dirty || this.referenceNumber.touched))
			){
				ret = false;
			}
			else if(!this.referenceNumberData) {
				ret = true;
			}
			else {
				ret = true;
			}

		}

		return ret;
	}

	checkMember() {
		let ret = true;
		if (
			!this.firstName.value ||
			!this.options.noMiddle && !this.options.onlyOneName && !this.middleName.value ||
			!this.options.onlyOneName && !this.lastName.value ||
			!this.birthDate.value ||
			!this.gender.value ||
			!this.civilStatus.value ||

			(this.firstName.errors && (this.firstName.dirty || this.firstName.touched)) ||
			(this.middleName.errors && (this.middleName.dirty || this.middleName.touched)) ||
			(this.lastName.errors && (this.lastName.dirty || this.lastName.touched)) ||
			(this.birthDate.errors && (this.birthDate.dirty || this.birthDate.touched)) ||
			(this.gender.errors && (this.gender.dirty || this.gender.touched)) ||
			(this.civilStatus.errors && (this.civilStatus.dirty || this.civilStatus.touched))
		) {
			ret = false;
		}

		return ret;
	}

	checkAdditional() {
		let ret = true;
		if (
			!this.street.value ||
			!this.province.value ||
			!this.municipality.value ||
			!this.zipCode.value ||
			!this.mobileNumber.value ||
			!this.emailAddress.value ||

			(this.street.errors && (this.street.dirty || this.street.touched)) ||
			(this.province.errors && (this.province.dirty || this.province.touched)) ||
			(this.municipality.errors && (this.municipality.dirty || this.municipality.touched)) ||
			(this.zipCode.errors && (this.zipCode.dirty || this.zipCode.touched)) ||
			(this.mobileNumber.errors && (this.mobileNumber.dirty || this.mobileNumber.touched)) ||
			(this.emailAddress.errors && (this.emailAddress.dirty || this.emailAddress.touched))
		) {
			ret = false;
		}

		return ret;
	}

	togglePanel(panel) {
		if(this.panel[panel]){
			return false;
		}

		this.registerForm.reset();

		this.panel = {
			policy: false,
			reference: false
		};

		this.panel[panel] = true;
	}

	// getProducts(planType, productType) {
	// 	this.productApi.findOne({ where : { plantype: planType, productType: productType }})
	// 	.subscribe(
	// 		response => {
	// 			console.log('PRODUCT: ', response);
	// 			this.productData = response;
	// 			// console.log(this.referenceNumberData);
	// 		},
	// 		error => {

	// 			// this.getGenders();
	// 			console.log('ERROR: ', error.code);
	// 		}
	// 	);
	// }

	getGenders() {
		this.gender_reloading = true;
		this.genderApi.find()
		.subscribe(
			response => {
				this.gender_reloading = false;
				this.genders = response;
			},
			error => {
				this.gender_reloading = false;
				// this.getGenders();
				console.log('ERROR: ', error.code);
			}
		);
	}

	getCivilStatuses() {
		this.civilStatus_reloading = true;
		this.civilStatusApi.find()
		.subscribe(
			response => {
				this.civilStatus_reloading = false;
				this.civilStatuses = response;
			},
			error => {
				this.civilStatus_reloading = false;
				// this.getCivilStatuses();
				console.log('ERROR: ', error.code);
			}
		);
	}

	getProvinces() {
		this.province_reloading = true;
		this.provinceApi.find()
		.subscribe(
			response => {
				this.province_reloading = false;
				this.provinces = response;
			},
			error => {
				this.province_reloading = false;
				// this.getProvinces();
				console.log('ERROR: ', error.code);
			}
		);
	}

	provinceOnChange() {
		console.log(this.province.value);
		const province = this.province.value;
		this.registerForm.patchValue({
			additional: {
				municipality: null
			}
		});

		this.municipalityApi.find({ where: { provinceId: province.key } })
		.subscribe(
			response => {
				console.log(response);
				// for(const i in response) {
				// 	response[i].name = this.sanitizeHtml(response[i].name);
				// }
				this.municipalities = response;
			},
			error => {
				console.log('ERROR: ', error.code);
			}
		);
	}

	municipalityOnChange() {
		const municipality = this.municipality.value;

		const zipcode = this.municipalities.filter(arr => {
			return arr.id === municipality.id;
		});

		this.registerForm.patchValue({
			additional: {
				zipCode: zipcode[0].zipcode
			}
		});
	}

	emailAddressOnChange() {
		this.validateEmailAddress();

		this.registerForm.patchValue({
			account: {
				username: this.emailAddress.value
			}
		});
	}

	validateEmailAddress() {
		// no validation of email for a now
		// this.customerInfoApi.find({ 'where' : { 'emailAddress' : this.emailAddress.value }})
		// .subscribe(
		// 	response => {
		// 		if(response.length) {
		// 			this.emailValid = false;
		//
		// 			this.registerForm.patchValue({
		// 				additional: {
		// 					emailAddress: this.emailAddress.value
		// 				}
		// 			});
		// 		}
		// 		else {
		// 			this.emailValid = true;
		// 		}
		// 	},
		// 	error => {
		// 		console.log('ERROR: ', error.code);
		// 	}
		// );
	}

	validateMobileNumber() {
		console.log(this.mobileNumber.value);
		if(this.mobileNumber.value.length == 10){
			this.customerCardApi.validateMobileNumber(this.mobileNumber.value)
			.subscribe(
				response => {
					console.log(response.response[0]);
					this.mobileNumberValid = true;

					this.registerForm.patchValue({
						additional: {
							mobileNumber: this.mobileNumber.value
						}
					});

				},
				error => {
					console.log('ERROR: ', error);
					this.mobileNumberValid = false;

					this.registerForm.patchValue({
						additional: {
							mobileNumber: this.mobileNumber.value
						}
					});
				}
			);
		}
	}

	validateReferenceNumber() {

	}

	resendEmailVerification(data) {
		this.isLoading = true;
		const options = {
			email: data.emailAddress,
			firstName: data.name
		}

		this.customerInfoApi.resendEmailVerification(options)
		.subscribe(
			response => {
				// console.log('resend success: ', response);
		        this.isLoading = false;

				// if(response.length) {
				// 	this.emailValid = false;
				//
				// 	this.registerForm.patchValue({
				// 		additional: {
				// 			emailAddress: this.emailAddress.value
				// 		}
				// 	});
				// }
				// else {
				// 	this.emailValid = true;
				// }
			},
			error => {
				console.log('ERROR: ', error.code);
			}
		);
	}

	register() {
		// if(!this.referenceNumberData){
		// 	this.fetchReferenceNumberDetails(this.referenceNumber.value);
		// }

		this.isLoading = true;

		let form = {
			username: this.username.value,
			password: this.password.value,
			email: this.emailAddress.value,
			active: true,
			emailVerified: false,
			CustomerInfo: {},
			CustomerCard: {}
		};

		const civilStat = this.civilStatuses.filter(cs => cs.id == this.civilStatus.value);
		// const province = this.provinces.filter(a => a.id == this.province.value.id);
		// const municipality = this.municipalities.filter(a => a.id == this.municipality.value.id);
		// console.log(municipality);
		const info = {
			UserDataId: null,
			firstName: this.firstName.value,
			middleName: this.middleName.value,
			lastName: this.lastName.value,
			nameExtension: this.nameExtension.value,
			birthDateFormatted: this.birthDate.value.formatted,
			birthDate: new Date(this.birthDate.value.formatted + " 00:00:00"),
			gender: this.gender.value,
			civilStatus: this.civilStatus.value,
			civilStatusAbbr: civilStat[0].abbr,
			street: this.street.value,
			province: this.province.value.id,
			provinceName: this.province.value.name,
			municipality: this.municipality.value.id,
			municipalityName: this.municipality.value.name,
			zipCode: this.zipCode.value,
			mobileNumber: this.mobileNumber.value,
			emailAddress: this.emailAddress.value
		}

		const card = {
			UserDataId: null,
			cardType: '',
			cardNumber: this.cardNumber.value?this.cardNumber.value:null,
			policyNumber: this.policyNumber.value?this.policyNumber.value:null,
			referenceNumber: this.referenceNumber.value?this.referenceNumber.value:null,
			dateValidated: '',
			expirationDate: '',
			birthDate: this.birthDate.value.formatted,			
		}

		form.CustomerInfo = info;
		form.CustomerCard = card;

		// console.log(form);
		
		this.customerCardApi.register(form)
			.subscribe(
				response => {
					// console.log('response', response);
					const res = response.response.d;

					this.isLoading = false;
					this.tab = 4;

					this.generateCardImage();
				},
				error => {
					this.isLoading = false;
					// console.log('ERROR: ', error);
					this.toastr.error(error.message, 'ERROR');
				}
			);
	}

	generateCardImage() {
	   	this.customerCardApi.fetchReferenceNumber(this.referenceNumber.value || this.cardNumber.value)
	   	.subscribe(
		   	response => {
		   		const ref = response.response.d;

				console.log('fetchReferenceNumber', ref);
        this.productApi
        .findOne({ where : {
          and: [
            { planType: ref.PlanType },
            { productType: ref.ProductType }
          ]
        }})
				.subscribe(
					response => {
						if(response) {
							this.formatWelcome(response,!this.referenceNumber.value)
						}
					},
					error => {

						// this.getGenders();
						console.log('ERROR: ', error.code);
					}
				);
		   	},
		   	error => {
			   	this.validatingReferenceNumber = false;
				this.referenceNumberData = {};
				console.log('ERROR: ', error.code);

				// this.referenceNumber.setValue(referenceNumber, { onlySelf: true });
		   	}
	   	);
	}

	isReferenceNumber() {
		let ret = false;
		if(this.referenceNumber.value) {
			ret = true;
		}
		return ret;
	}

	hasten(n){
		if(this.tab == 4){
			return false;
		} else if(this.tab == 0) {
			this.bsModalRef = this.modalService.show(RegisterModalComponent,{});
		}

		this.tab = n;

		this.verification = {
			name: this.firstName.value,
			emailAddress: this.emailAddress.value
		}
	}

	testGenerate() {
		this.registerForm.reset();
		this.isLoading = true;
		const card = {
			UserDataId: null,
			cardType: 'primagold',
			cardNumber: this.cardNumber.value?this.cardNumber.value:null,
			policyNumber: this.policyNumber.value?this.policyNumber.value:null,
			referenceNumber: this.referenceNumber.value?this.referenceNumber.value:null,
			dateValidated: '2018-08-01',
			expirationDate: '2019-07-30'
		}

		this.customerCardApi.generate(card)
		.subscribe(
			response => {
				const res = JSON.parse(response.response);

				const card = res.Remarks.split(';');
				this.registerForm.patchValue({
					policy: {
						cardNumber: card[1],
						policyNumber: card[0]
					}
				});

				this.isLoading = false;
			},
			error => {
				this.toastr.error(error.code, 'ERROR');
				this.isLoading = false;
			}
		);
	}
	testGenerateRef() {
		this.registerForm.reset();
		this.isLoading = true;

		this.customerCardApi.generateReferenceNumber(undefined)
		.subscribe(
			response => {
				const res = response.response;

				this.registerForm.patchValue({
					policy: {
						referenceNumber: (res)?res[0].ReferenceNumber:''
					}
				});

				this.isLoading = false;
			},
			error => {
				this.toastr.error(error.code, 'ERROR');
				this.isLoading = false;
			}
		);
	}

	displayWelcomeMessage(productType) {
		console.log(this.productData);
		let ret = false;
		if(this.productData.productType == productType && this.referenceNumber.value) {
			ret = true;
		}

		return ret;
	}

	displayOldWelcomeMessage(productType) {
		let ret = false;
		if (this.productData.productType == productType && !this.referenceNumber.value){
			ret = true;
		}

		return ret;
	}

	/**
		<div *ngIf="displayOldWelcomeMessage('40')"> <!-- EREADY OLD -->
			<div class="h5" style="margin: 5% 0 2% 0;">Welcome! And thank you for choosing Maxicare.</div>
			<div class="h5" style="width: 60%; margin: 0 auto;"><span *ngIf="isReferenceNumber()"></span>Please wait for seven (7) days for your Maxicare EReady membership to be activated.</div>
		</div>
		<div *ngIf="displayWelcomeMessage('40') && (referenceNumberData.PlanType === 'P' || referenceNumberData.PlanType === 'T')"> <!-- EREADY -->
			<div class="h5" style="margin: 5% 0 2% 0;">Welcome! And thank you for choosing Maxicare.</div>
			<div class="h5" style="width: 60%; margin: 0 auto;"><span *ngIf="isReferenceNumber()"></span>Your Maxicare EReady membership is going be activated after seven (7) days. In the meantime, we shall be sending a membership confirmation via email and SMS within the next 24 hours.</div>
		</div>
		<div *ngIf="displayWelcomeMessage('40') && (referenceNumberData.PlanType === 'I' || referenceNumberData.PlanType === 'L')"> <!-- EREADY -->
			<div class="h5" style="margin: 5% 0 2% 0;">Welcome! And thank you for choosing Maxicare.</div>
			<div class="h5" style="width: 60%; margin: 0 auto;"><span *ngIf="isReferenceNumber()"></span>Your Maxicare EReady membership is going be activated after fifteen (15) days. In the meantime, we shall be sending a membership confirmation via email and SMS within the next 24 hours.</div>
		</div>

		this.card_link = 'https://s3-ap-southeast-1.amazonaws.com/maxicare-eshop/img/'+filename+'.png';
		this.card_link = 'https://shop.maxicare.com.ph/main/img/'+response['sku']+'.png';
	 * @returns 
	 */
	formatWelcome(productData,isOld: boolean) {
		this.productData = productData
		if (productData) {
			const { sku, planType, productType } = productData
			let filename = sku
			this.welcome.message = 'Welcome! And thank you for choosing Maxicare.'
			if (['40','43'].includes(productType)) {
				this.welcome.show = true
				this.welcome.activateMessage = isOld
				? 'Please wait for seven (7) days for your Maxicare EReady membership to be activated.'
				: 'Your Maxicare EReady membership is going be activated after fifteen (15) days. In the meantime, we shall be sending a membership confirmation via email and SMS within the next 24 hours.'

			}
			if (['42'].includes(productType)) {
				this.welcome.show = true
				this.welcome.activateMessage = isOld
				? 'Your Maxicare PRIMA membership is already active. You may start availing of your healthcare benefits once you receive your membership confirmation via email or SMS within the next 24 hours.'
				: 'Your Maxicare PRIMA membership is already active. You may start availing of your healthcare benefits once you receive your membership confirmation via email or SMS within the next 24 hours.'

			}
			if (['I','L'].includes(planType)) {
				this.welcome.activateMessage = 'Your Maxicare EReady membership is going be activated after fifteen (15) days. In the meantime, we shall be sending a membership confirmation via email and SMS within the next 24 hours.'
				switch(sku) {
					case 'ereadytitanium2023':
						filename = 'ereadytitanium_enhanced'; break;
					case 'ereadyplatinum2023':
						filename = 'ereadyplatinum_enhanced'; break;
					case 'ereadyadvancet2023':
						filename = 'ereadyadvancet'; break;
					case 'ereadyadvancep2023':
						filename = 'ereadyadvancep'; break;
				}
			}
			if (['D','R'].includes(planType)) {
				this.welcome.activateMessage = 'Your Maxicare PRIMA membership is already active. You may start availing of your healthcare benefits once you receive your membership confirmation via email or SMS within the next 24 hours.'
				switch(sku) {
					case 'primagold2023':
						filename = 'primagold'; break;
					case 'primasilver2023':
						filename = 'primasilver'; break;
				}
			}
			this.welcome.link = 'https://s3-ap-southeast-1.amazonaws.com/maxicare-eshop/img/'+filename+'.png';
		
			console.log('this welcome',this.welcome)
		}
	}

	
	isCaptchaNeeded() {
		let ret = false;
		if(this.server == "https://memberviewpoint.maxicare.com.ph") {
			ret = true;
		}
		return ret;
	}

	// testRegister() {
	// 	const card = {
	// 		UserDataId: null,
	// 		cardType: 'primagold',
	// 		cardNumber: this.cardNumber.value?this.cardNumber.value:null,
	// 		policyNumber: this.policyNumber.value?this.policyNumber.value:null,
	// 		referenceNumber: this.referenceNumber.value?this.referenceNumber.value:null,
	// 		dateValidated: '2018-08-01',
	// 		expirationDate: '2019-07-30'
	// 	}
	//
	// 	this.customerCardApi.register(card)
	// 	.subscribe(
	// 		response => {
	// 			console.log(response);
	// 		},
	// 		error => {
	// 			this.isLoading = false;
	// 			console.log('ERROR: ', error.code);
	// 		}
	// 	);
	// }

}
